$(document).ready(function() {
    /**
     * Inject div.optimised-text between all elements to be optimised and their content.
     * @param element
     */
    function prepareText(element) {
        var contents = element.contents();
        var optimisedText = $('<div class="optimised-text"></div>');
        optimisedText.append(contents);
        element.append(optimisedText);
        element.css('font-size', element.data('fit-text') || 100);
    }

    /**
     * Revert changes caused by the textFit library. Don't revert changes of prepareText().
     * @param element
     */
    function resetTextFit(element) {
        if (element.find('span.textFitted').length > 0) {
            var contents = element.find('span.textFitted').contents();
            element.find('span.textFitted').replaceWith(contents);
        }
    }

    /**
     * Run Hyphenator.js programmatically on elements to be optimised.
     * @param element
     */
    function hyphenateText(element) {
        if(!element.hasClass('donthyphenate')) {
            Hyphenator.hyphenate(element[0], $('html').attr('lang') || 'de');
        }
    }

    /**
     * Determine, wether content needs to be optimized by the fitText library. Run hyphenateText() first and only apply fitText if the content exceeds the parents boundaries.
     */
    function optimiseText() {
        $('*[data-fit-text]').each(function(index, box) {
            box = $(box);
            if(box.find('div.optimised-text').length === 0) {
                prepareText(box);
            }
            resetTextFit(box);
            hyphenateText(box);

            var content = box.find('div.optimised-text').first();

            if(box.height() < content.outerHeight(true)) {
                fitText(box);
            }
        });
    }

    /**
     * Run the fitText library on element.
     * @param element
     */
    function fitText(element) {
        var maxFontSize = element.data('fit-text') || 100;
        textFit(element[0], {
            alignVert: false,
            alignHoriz: false,
            multiLine: true,
            detectMultiLine: true,
            minFontSize: 6,
            maxFontSize: maxFontSize,
            reProcess: true,
            widthOnly: false,
            alignVertWithFlexbox: false,
        });
    }

    optimiseText();
    $(window).resize(optimiseText);
});
